import axios from 'axios';
import {useCookies} from 'vue3-cookies';

const {cookies} = useCookies();

const service = axios.create({
    // baseURL: 'http://localhost:1001/api',
    baseURL: 'http://test.kkkit.cn/api',
    timeout: 36000
})

service.interceptors.request.use(
    config => {
        if (cookies.get("access_token")) {
            config.headers.Authorization = cookies.get("access_token")
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

//相应res.code !== 20000
service.interceptors.response.use(
    response => {
        if (response.data.code === 401)
            window.location.href = '/login';
        return response.data
    },
    error => {
        return Promise.reject(error)
    }
)


export function get(url, params) {
    return service({
        url: url,
        method: 'get',
        params: params
    })
}

export function post(url, params) {
    return service({
        url: url,
        method: 'post',
        data: params
    })
}
