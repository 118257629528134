<template>

  <div class="w-full h-full xl:py-8 bg-slate-100 transition duration-75">

    <div
        class="xl:h-[calc(100vh-4rem)] max-xl:h-[100vh] xl:w-[1280px] xl:mx-auto md:flex bg-slate-50 xl:shadow xl:rounded-xl xl:border-gray-200 xl:border">
      <u-left class="md:w-[25%]"/>
      <u-right class="md:w-[75%] bg-white rounded-xl border-gray-200 border"/>
    </div>

    <!--主题切换-->
    <!--<div class="fixed bottom-10 right-10">
      <label class="swap swap-rotate">
        <input data-act-class="ACTIVECLASS" data-toggle-theme="dark,light" type="checkbox"
               @click="switchTheme"/>
        <span class="swap-on fill-current mgc_sun_fog_line text-4xl"/>
        <span class="swap-off fill-current mgc_moon_fog_line text-4xl"/>
      </label>
    </div>-->
  </div>

</template>

<script>
// import {themeChange} from "theme-change";
import ULeft from "@/components/layout-user/ULeft";
import URight from "@/components/layout-user/URight";

export default {
  components: {ULeft, URight},
  mounted() {
    // themeChange(false);
  },
  methods: {
    /*切换主题*/
    /*switchTheme() {
      if (localStorage.theme === 'dark') {
        document.documentElement.classList.remove('dark')
        document.documentElement.classList.add('light')
      } else {
        document.documentElement.classList.remove('light')
        document.documentElement.classList.add('dark')
      }
    }*/
  }
}
</script>

<style scoped>
</style>
