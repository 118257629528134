<template>

  <div class="py-4 px-6 relative text-gray-600">
    <!--头像、昵称-->
    <div class="flex">
      <img alt="" class="w-20 h-20" :src="this.$store.state.webInfo.logo">
      <div class="ml-4 pt-4">
        <h1 class="text-2xl font-semibold tracking-[0.2em]">{{ $store.state.webInfo.name }}</h1>
        <p class="text-sm mt-1">{{ $store.state.webInfo.address }}</p>
      </div>
    </div>

    <!--分割-->
    <div class="divider my-0"/>

    <!--座右铭-->
    <p class="text-base">{{ $store.state.webInfo.sign }}</p>

    <!--菜单-->
    <div class="flex justify-between mt-4">
      <router-link v-for="item in menuList"
                   :to="item.url"
                   :data-tip="item.name"
                   class="p-2 tooltip shadow rounded-lg border-2 cursor-pointer duration-100 bg-white border-gray-100 hover:border-blue-400 hover:text-blue-400">
        <i :class="item.icon+' text-3xl'"/>
      </router-link>
    </div>

    <!--音乐-->
    <!--<div class="mt-6 w-full max-md:hidden">
      <audio ref="audio" @timeupdate="musicTimeUpdate">
        <source src="../../../src/assets/feng.mp3" type="audio/mp3"/>
      </audio>

      <div class="mx-auto flex justify-between">
        <div class="w-[70%] rounded-lg aspect-square bg-cover	bg-no-repeat bg-center relative shadow-lg border-2"
             style="background-image: url('https://img1.baidu.com/it/u=137662096,3508639311&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500')">
          <div class="w-full h-12 absolute bottom-0 rounded-b-lg bg-gradient-to-t from-black/80 via-black/50 px-2">
            <p class="text-lg text-gray-200">枫</p>
            <p class="text-xs text-gray-400">周杰伦</p>
          </div>
        </div>

        <div class="text-center leading-[3rem] flex flex-col gap-4 justify-center">
          <button class="music-btn"><i class="mgc_large_arrow_left_line text-2xl"/></button>
          <label class="swap swap-rotate music-btn">
            <input type="checkbox" v-model="musicPlay" @change="musicBtn"/>
            <i class="swap-off fill-current mgc_play_line text-3xl cursor-pointer"/>
            <i class="swap-on fill-current mgc_pause_line text-3xl cursor-pointer"/>
          </label>
          <button class="music-btn"><i class="mgc_large_arrow_right_line text-2xl"/></button>
        </div>
      </div>

      &lt;!&ndash;<progress :value="progressValue" class="progress h-1" max="100"/>&ndash;&gt;
      <input type="range" min="0" max="100" v-model="progressValue" @change="rangeChange"
             class="range range-xs h-[0.2rem]"/>
    </div>-->

    <!--站长简介-->
    <div class="mt-4 max-md:hidden">
      <v-md-editor mode="preview" v-model="this.$store.state.webInfo.custom"/>
    </div>

    <!--版权-->
    <div class="absolute bottom-4 w-[calc(100%-2.5rem)] text-center max-md:hidden">
      <div class="divider my-0"/>
      <p class="text-gray-400 mb-2">{{ $store.state.webInfo.copyright }}</p>
      <p class="text-xs tracking-tight">
        <a class="text-gray-300" target="_blank" href="https://beian.miit.gov.cn/">{{ $store.state.webInfo.icp }}</a>
      </p>
      <p class="text-xs tracking-tight" v-if="this.$store.state.webInfo.gongan">
        <a class="text-gray-300 flex justify-center" target="_blank"
           :href="'https://beian.mps.gov.cn/#/query/webSearch?code='+this.$store.state.webInfo.gongan">
          <img src="../../assets/img/gongan.png" class="w-4	h-4" alt=""/>
          {{ $store.state.webInfo.gongan }}
        </a>
      </p>
    </div>
  </div>

</template>

<script>
export default {
  name: "ULeft",
  data() {
    return {
      progressValue: 0,
      musicPlay: false,
      musicList: [],
      menuList: [
        {name: '首页', icon: 'mgc_house_line', url: '/'},
        {name: '归档', icon: 'mgc_folder_open_line', url: '/archives'},
        {name: '相册', icon: 'mgc_photo_album_line', url: '/photo'},
        {name: '友链', icon: 'mgc_link_line', url: '/link'},
      ]
    }
  },
  methods: {
    //-----------------音乐---------------
    //开始/暂停播放
    musicBtn() {
      this.musicPlay ? this.$refs.audio.play() : this.$refs.audio.pause();
    },
    //计算音乐播放%
    musicTimeUpdate(e) {
      this.progressValue = e.target.currentTime / e.target.duration * 100;
    },
    //改变进度
    rangeChange(e) {
      let audio = this.$refs.audio;
      audio.pause();
      audio.currentTime = this.progressValue * audio.duration / 100
      audio.play();
      this.musicPlay = true;
    },
    //------------------音乐end------------
  }
}
</script>

<style scoped>
@tailwind utilities;

@layer utilities {
  .music-btn {
    @apply w-12 h-12 shadow rounded-2xl border-2 cursor-pointer duration-100 bg-white border-gray-100 hover:border-blue-400 hover:text-blue-400;
  }
}

input[type="range"]::-webkit-slider-thumb {
  width: 0;
}

/deep/.v-md-editor{
  background-color: transparent;
}
</style>
