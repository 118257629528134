<template>

  <div class="fixed z-50 top-5 right-5 fade-in" v-show="isVisible">
    <div class="alert shadow-lg" ref="alert">
      <span>{{ message }}</span>
      <i class="mgc_close_circle_fill text-xl cursor-pointer" @click="isVisible=false"/>
    </div>
  </div>

</template>

<script>
export default {
  name: "Toast",
  data() {
    return {
      message: '',
      alertType: '',
      isVisible: false
    };
  },
  methods: {
    //info success warning error
    showToast(message, type = 'success') {
      this.message = message;
      this.isVisible = true;
      setTimeout(() => {
        this.$refs.alert.classList.add('alert-' + type);
      }, 500)
      setTimeout(() => {
        this.isVisible = false;
      }, 3500);
    }
  }
}
</script>

<style scoped>
.fade-in {
  opacity: 0;
  animation: fadeInAnimation 0.5s ease forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
