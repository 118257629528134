import {createApp} from 'vue';
import App from '@/App.vue';
import '@/assets/icon/Mingcute_mini.css' //图标
import '@/assets/font.css' //字体
import './index.css' //公用样式
import loading from '@/assets/img/loading.gif'
import {get} from "@/utils/request";
import store from "@/store";
import router from "@/router";

const app = createApp(App);

app.config.globalProperties.$store = store;

import VueLazyload from 'vue-lazyload';//图片懒加载
app.use(VueLazyload, {
    preLoad: 0.8,
    loading: loading,
    attempt: 1
});
import VueCookies from 'vue3-cookies';
import {showToast} from '@/utils/tools'//提示


get('/getConfig').then(res => {
    let data = {
        title: res.data.webTitle,
        subTitle: res.data.webSubTitle,
        icon: res.data.webIcon,
        logo: res.data.webLogo,
        keywords: res.data.keywords,
        description: res.data.description,
        name: res.data.userName,
        address: res.data.userAddress,
        sign: res.data.userSign,
        copyright: res.data.webCopyright,
        icp: res.data.webIcp,
        gongan: res.data.webGongan,
        skm: res.data.webSkm,
        newsTitle: res.data.webNewsTitle,
        newsSubTitle: res.data.webNewsSubTitle,
        custom: res.data.webCustom
    }
    store.commit("SET_WEBINFO", data);
    document.querySelector('meta[name="keywords"]').setAttribute('content', data.keywords);
    document.querySelector('meta[name="description"]').setAttribute('content', data.description);
    document.querySelector('link[rel="icon"]').setAttribute('href', data.icon);
    app.use(router);
    app.mount('#app');
});

//----------------------MD----------------------
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import hljs from 'highlight.js';
//======轻量版编辑器======
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
// Tip 提示插件
import createTipPlugin from '@kangc/v-md-editor/lib/plugins/tip/index';
import '@kangc/v-md-editor/lib/plugins/tip/tip.css';
// Emoji 表情插件
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index';
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css';
// Mermaid 插件（流程图等）
// import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn';
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css';
// TodoList 任务列表
import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index';
import '@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css';
// Copy Code 快捷复制代码
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
// Align 内容定位
import createAlignPlugin from '@kangc/v-md-editor/lib/plugins/align';

//md编辑器
VMdEditor.use(githubTheme, {
    Hljs: hljs,
});
VMdEditor.use(createTipPlugin());
VMdEditor.use(createEmojiPlugin());
// VMdEditor.use(createMermaidPlugin());
VMdEditor.use(createTodoListPlugin());
VMdEditor.use(createCopyCodePlugin());
VMdEditor.use(createAlignPlugin());


app.config.globalProperties.$toast = showToast;
app.config.globalProperties.$cookies = VueCookies;

app.use(VueCookies).use(VMdEditor);
