import {createRouter, createWebHistory} from "vue-router";
import store from "@/store";
import LayoutUser from '@/components/layout-user'
import LayoutAdmin from '@/components/layout-admin'
import {useCookies} from 'vue3-cookies';

const {cookies} = useCookies();

const routes = [
    {
        path: '/',
        component: LayoutUser,
        meta: {auth: false},
        children: [
            {
                path: '/',
                component: () => import('@/views/user/index'),
                meta: {auth: false}
            },
            {
                path: '/archives',
                component: () => import('@/views/user/archives'),
                meta: {auth: false, title: "归档"}
            },//归档
            {
                path: '/link',
                component: () => import('@/views/user/link'),
                meta: {auth: false, title: "友链"}
            },//友链
            {
                path: '/photo',
                component: () => import('@/views/user/photo'),
                meta: {auth: false, title: "相册"}
            },//相册
            {
                path: '/:url',
                component: () => import('@/views/user/content'),
                meta: {auth: false}
            },//文章
            {
                path: '/type/:type',
                component: () => import('@/views/user/list'),
                meta: {auth: false}
            },//分类
            {
                path: '/tag/:tag',
                component: () => import('@/views/user/list'),
                meta: {auth: false}
            },//标签
            {
                path: '/search/:search',
                component: () => import('@/views/user/list'),
                meta: {auth: false}
            },//搜索
            {
                path: 'NotFound',
                component: () => import('@/views/404'),
                meta: {auth: false}
            }
        ]
    },
    {
        path: '/login',
        component: () => import('@/views/admin/login'),
        meta: {auth: false},
    },
    {
        path: '/admin',
        component: LayoutAdmin,
        meta: {auth: true},
        children: [
            {
                path: '/admin',
                component: () => import('@/views/admin/index'),
                meta: {auth: true}
            },
            {
                path: '/admin/writing',
                component: () => import('@/views/admin/writing'),
                meta: {auth: true}
            },//编写文章
            {
                path: '/admin/content',
                component: () => import('@/views/admin/content'),
                meta: {auth: true}
            },//文章管理
            {
                path: '/admin/type',
                component: () => import('@/views/admin/type'),
                meta: {auth: true}
            },//分类管理
            {
                path: '/admin/news',
                component: () => import('@/views/admin/news'),
                meta: {auth: true}
            },//新闻管理
            {
                path: '/admin/comment',
                component: () => import('@/views/admin/comment'),
                meta: {auth: true}
            },//评论管理
            {
                path: '/admin/link',
                component: () => import('@/views/admin/link'),
                meta: {auth: true}
            },//友链管理
            {
                path: '/admin/photo',
                component: () => import('@/views/admin/photo'),
                meta: {auth: true}
            },//相册管理
            {
                path: '/admin/setting',
                component: () => import('@/views/admin/setting'),
                meta: {auth: true}
            },//网站设置
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),//这个模式可以去除路由中的
    routes
});

//前置路由守卫
router.beforeEach((to, from, next) => {
    if (!to.meta.auth) {
        let data = store.state.webInfo;
        setITDK(to.meta, data)
    }
    if (to.meta.auth && !cookies.get("access_token")) {
        next('/login')
    } else {
        next();
    }
});


function setITDK(meta, data) {
    if (!meta.title) {
        document.title = data.title + ' - ' + data.subTitle;
    } else {
        document.title = meta.title + ' - ' + data.title
    }
}


export default router
